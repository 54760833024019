import { ref, computed, onMounted, nextTick, watch } from "vue";

import { useContext } from "@nuxtjs/composition-api";

import axios from "axios";
import omit from "lodash/omit";


export default {
  __name: 'index',
  props: {
  dataProvider: {
    type: Object,
    required: true,
  },
  itemsPerPage: {
    type: Number,
    required: false,
  },
  loadMoreUrl: {
    type: Function,
    required: false,
  },
  loadMoreCallback: {
    type: Function,
    required: false,
  },
  loadMoreItems: {
    type: Function,
    required: false,
  },
},
  setup(__props) {

const props = __props;



const { route, store } = useContext();
const loading = ref(false);

const ref_btnLoadMore = ref(null);

const title = computed(() => {
  let s;

  switch (props.dataProvider.variant) {
    case "awards":
      s = store.state.labels.awards;
      break;

    default:
      s = props.dataProvider.title;
      break;
  }

  return s;
});

const refItems = ref(props.dataProvider.items);
const refTotal = ref(props.dataProvider.total);

watch(
  () => props.dataProvider.items,
  (val) => {
    refItems.value = val;
  }
);

watch(
  () => props.dataProvider.total,
  (val) => {
    refTotal.value = val;
  }
);

const items = computed(() => {
  return refItems.value;
});

const total = computed(() => refTotal.value);

const paginationEnabled = computed(() => {
  return items.value && items.value.length < total.value;
});

function loadMore(offset) {
  let nItems = props.itemsPerPage;
  let nOffset = items.value.length;

  if (offset && offset > items.value.length) {
    nOffset = items.value.length;
    nItems = offset - items.value.length;
  }

  if (props.loadMoreCallback) {
    loading.value = true;
    props.loadMoreCallback(null, nOffset, nItems).then(() => {
      loading.value = false;
    });
  }

  if (!props.loadMoreUrl) {
    return;
  }

  loading.value = true;

  const scrollY = offset
    ? 0
    : parseInt(
        ref_btnLoadMore.value.$el.getBoundingClientRect().y + window.scrollY
      );

  return axios
    .get(props.loadMoreUrl(null, nOffset, nItems))
    .then(({ data }) => {
      refItems.value = refItems.value.concat(
        props.loadMoreItems
          ? props.loadMoreItems(data)
          : data.body.content[0].data.items
      );

      refTotal.value = data.total;

      loading.value = false;

      let params = omit(
        Object.fromEntries(new URLSearchParams(location.search)),
        ["offset", "visual-search"]
      );

      let query = "";
      for (const key in params) {
        query += `${(query.length ? "&" : "") + key}=${params[key]}`;
      }

      window.history.replaceState(
        null,
        "",
        `?${query}${query.length ? "&" : ""}offset=${items.value.length}`
      );

      gsap.to(window, {
        duration: 1,
        scrollTo: { y: scrollY - 70 },
        ease: "expo.inOut",
      });
    });
}

watch(loading, (newVal, oldVal) => {
  ScrollTrigger.refresh(true);
});

onMounted(() => {
  if (isFinite(parseInt(route.value.query.offset))) {
    loadMore(parseInt(route.value.query.offset))?.then((data) => {
      nextTick(() => {
        ScrollTrigger.refresh(true);
      });
    });
  }
});

return { __sfc: true,props, route, store, loading, ref_btnLoadMore, title, refItems, refTotal, items, total, paginationEnabled, loadMore }
}

}