export const state = () => ({
  type: null,
  toolbarFacetsOriginal: null,
  toolbarFacets: null,
  data: {
    updateTime: 1,
  },
  formValues: {},
});

export const mutations = {
  setToolbar(state, payload) {
    state.type = payload;
  },
  setFormValues(state, payload) {
    state.formValues = payload;
  },
  setToolbarFacets(state, payload) {
    state.toolbarFacets = payload;
    if (!state.toolbarFacetsOriginal) {
      state.toolbarFacetsOriginal = payload;
    }
  },
  disposeToolbarFacets(state, payload) {
    state.formValues = {};
    state.toolbarFacets = null;
    state.toolbarFacetsOriginal = null;
  },
  resetToolbarFacets(state) {
    state.toolbarFacets = state.toolbarFacetsOriginal;
  },
  setDataByRouteName(state, payload) {
    state.data[payload.routeName] = payload.data;
    state.data.updateTime = Date.now();
  },
};

export const actions = {};

export const getters = {
  loadedToolbar(state) {
    return state.type;
  },
};
